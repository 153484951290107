export const STAND_ALONE = window._env_.STAND_ALONE;
export const STAND_ALONE_IP_SERVER_MEDIAMTX = window._env_.STAND_ALONE_IP_SERVER_MEDIAMTX;

const URL_BACKEND = window._env_.STAND_ALONE_BACKEND;
const URL_RESOURCES = window._env_.STAND_ALONE_RESOURCES;

export const getBackendUrl = () => {
  return URL_BACKEND;
};

export const getResourceUrl = () => {
  return URL_RESOURCES;
};
const BASE_URL = getBackendUrl() + "api/";
//--------------------------------------------------------------
export const DEFAULT_LANGUAGE = localStorage.getItem("lang")
  ? localStorage.getItem("lang")
  : "ja"; // ja or en
export const DEFAULT_DISTANCE_TO_MARKER = 40;
export const WS_URL = getBackendUrl() + "drone-status";
export const CORP_LOGO_DEFAULT = "Master/corp_default_icon";

export const MEDIA_URL = BASE_URL + "media/";
export const LOGIN_URL = BASE_URL + "auth/signin";
export const SIGNUP_URL = BASE_URL + "auth/signup";
export const REFRESH_TOKEN_URL = BASE_URL + "auth/refreshtoken";
export const REVOKE_TOKEN_URL = BASE_URL + "auth/revoke/";
export const UPLOAD_URL = BASE_URL + "upload/image";
export const SEARCH_URL = BASE_URL + "search/image";

export const USERS_URL = BASE_URL + "user/user/";
export const USER_ADMIN_URL = BASE_URL + "user/admin";
export const USER_ADD_URL = BASE_URL + "user/signup";
export const USER_EDIT_URL = BASE_URL + "user/";
export const USER_DELETE_URL = BASE_URL + "user/";
export const USER_SEARCH_URL = BASE_URL + "user/search-user";
export const CHANGE_AVATAR_URL = BASE_URL + "user/avatar/";
export const CHANGE_FIRST_CORP_KEY_URL = BASE_URL + "user/change-corp-key/";
export const CHANGE_SECOND_CORP_KEY_URL =
  BASE_URL + "user/change-second-corp-key/";
export const CHANGE_RECOGNITION_STATUS_URL =
  BASE_URL + "user/change-recognition-status/";
export const CHANGE_NOTIFICATION_FOR_DOWNLOAD_MEDIA_URL =
  BASE_URL + "user/change-media-download-notification-url";
export const GET_NOTIFICATION_FOR_DOWNLOAD_MEDIA_URL =
  BASE_URL + "user/get-media-download-notification-url";

export const CHARGING_STATION_URL = BASE_URL + "charging-station/all";
export const CHARGING_STATION_DELETE_URL = BASE_URL + "charging-station/delete";
export const CHARGING_STATION_ADD_URL = BASE_URL + "charging-station/add";
export const GET_STATION_STATUS = BASE_URL + "station/";
export const UPDATE_STATUS_STATION = BASE_URL + "station/status";

export const PLAN_URL = BASE_URL + "plan/";
export const PLAN_LIST_URL = BASE_URL + "plan/list";
export const PLAN_LIST_SIMPLE_URL = BASE_URL + "plan/list-simple";
export const PLAN_ADD_URL = BASE_URL + "plan/upload-plan";
export const PLAN_DETAIL_URL = BASE_URL + "plan/detail/";
export const PLAN_UPDATE_URL = BASE_URL + "plan/update-plan";
export const PLAN_DELETE_URL = BASE_URL + "plan/";
export const PLAN_SEARCH_URL = BASE_URL + "plan/search";
export const PLAN_DOWNLOAD_URL = BASE_URL + "plan/download/";
export const PLAN_CREATE_FLIGHT_URL = BASE_URL + "plan/create-drone-plan/";
export const PLAN_UPDATE_FLIGHT_URL = BASE_URL + "plan/update-drone-plan/";
export const PLAN_UPDATE_MAP_EDITOR_URL = BASE_URL + "plan/update-map-editor";
export const PLAN_MEMO_LATEST = BASE_URL + "plan/memo_latest";

export const EMAIL_LIST_URL = BASE_URL + "email/list";
export const EMAIL_DELETE_URL = BASE_URL + "email/delete";
export const EMAIL_ADD_URL = BASE_URL + "email/add";
export const SEND_EMAIL_GET_RESET_PASSWORD =
  BASE_URL + "auth/reset-password/send-mail";
export const SEND_RESET_PASSWORD = BASE_URL + "auth/reset-password";

export const DEVICE_STATUS_URL = BASE_URL + "status/";
export const DEVICE_STATUS_ALL_URL = BASE_URL + "status/all";
export const DEVICE_ALERT_ALL_URL = BASE_URL + "status/alert/";
export const DEVICE_RESULT_URL = BASE_URL + "result/";
export const RESULT_DELETE_URL = BASE_URL + "result/delete/";
export const ZOOM_IMAGE_URL = BASE_URL + "result/images";
export const LIST_DEVICE_HISTORY_URL = BASE_URL + "result/history/all";
export const LIST_DEVICE_HISTORY_URL_SEARCH = BASE_URL + "result/history/search";
export const LIST_IMAGE_BY_INSPECTION_ID = BASE_URL + "result/images/inspectionId";
export const LIST_DEVICE_URL = BASE_URL + "device/all";
export const DELTE_DEVICE_URL = BASE_URL + "device/delete/";
export const DOWNLOAD_IMAGE = BASE_URL + "result/image/download";
export const REMOVE_IMAGE = BASE_URL + "result/image/delete";

export const MASTER_DATA_URL = BASE_URL + "masterData/";
export const MASTER_DATA_ICON_URL = BASE_URL + "masterData/upload";
export const MASTER_DATA_PARAMETTER_URL = BASE_URL + "masterData/parametter";

export const ARUCO_URL = BASE_URL + "aruco/information";

export const DRONE_TYPE_URL = BASE_URL + "drone-type";
export const DRONE_TYPE_UD_URL = BASE_URL + "drone-type/";

// ADM privacy support terms-of-use
export const CONTENT_URL = BASE_URL + "content/";

export const SCHEDULE_URL = BASE_URL + "schedule/all";
export const SCHEDULE_SEARCH_URL = BASE_URL + "schedule/search";
export const SCHEDULE_UD_URL = BASE_URL + "schedule/";
export const SCHEDULE_C_URL = BASE_URL + "schedule";
export const SCHEDULE_TAKEOFF_NOW_URL = BASE_URL + "schedule/takeoff/";
// export const RESULT_DOWNLOAD_URL =  BASE_URL + "result/download_result";
export const RESULT_DOWNLOAD_URL = BASE_URL + "result/get_result_info";

export const SYSTEM_INFO_URL = BASE_URL + "system/";

export const SENSORS_INFO = BASE_URL + "drone/sensor";
export const GET_FLIGHT_PLAN = BASE_URL + "drone/getFlightPlan";
export const CSV = BASE_URL + "drone/csv";

export const TAKEOFF_MARKER_ID = 0;
export const LANDING_MARKER_ID = -1;

export const FE_VER = "6.0.10";

export const FE_MINOR_VER_FORMAT = "YYMMDDHHmm";