let lang_en = {
  "invalid.param": "Invalid parameters",
  "another.error": "An error Happened",
  "get.success": "Get data successfully",
  "post.success": "Saved",
  "post.err": "Not Saved",
  "put.success": "Saved",
  "put.err": "Not Saved",
  "delete.success": "Delete data successfully",
  "name.used": "Name is used",
  "name.empty": "Name is empty",
  "exist.drone.type": "Drone type exists",
  "500": "Internal server error",
  "400": "Bad request",
  "401": "Unauthorized",
  "404": "Request not found",
  "exist.plan.number": "Plan number exists",
  "data.empty": "Empty data",
  "language": "Language",
  "common": {
    "none": "none",
    "drone": "Drone",
    "robot": "Robot",
    "yes": "Yes",
    "no": "No",
    "left": "Left",
    "right": "Right",
    "turn": "Turn",
    "landing": "Landed",
    "landed": "Landed",
    "up": "Up",
    "down": "Down",
    "sensor": "Sensor",
    "marker": "Marker",
    "takeoff_btn": "Take Off",
    "takeoff": "Take off",
    "right_90": "Rotate 90 degrees to right",
    "left_90": "Rotate 90 degrees to left",
    "save": "Save",
    "back": "Back",
    "ok": "OK",
    "cancel": "Cancel",
    "confirm_back": "Do you want to go back?",
    "map_mode": "Map mode",
    "developer_mode": "Developer mode",
    "please_select": "Please select",
    "departure": "Departure",
    "arrival": "Arrival",
    "error": "Error",
    "please_enter": "Please input",
    "item": "Item",
    "value": "Value",
    "message_set_value": "Please fill in the value.",
    "success": "Successful",
    "notifi_error3": "User update fails",
    "message_input": "Please enter",
    "battery": "battery",
    "not_found": "Not Found",
    "preparing": "Non-Charging",
    "charging": "Charging",
    "image": "Image",
    "video": "Video",
    "type": "Media Type",
    "hold_t": "Hold Time(s)",
    "rotate": "Rotate(deg)",
    "angle": "Direction Angle",
    "no-signal": "No signal",
    "ready": "Ready",
    "on_the_way": "Flight",
    "during_the_move": "During the move",
    "NA": "-----",
    "invalid_email": "is invalid email",
    "Sun": "Sun",
    "Mon": "Mon",
    "Tue": "Tue",
    "Wed": "Wed",
    "Thu": "Thu",
    "Fri": "Fri",
    "Sat": "Sat",
    "*": "*",
    "add": "Add New",
    "add_st": "Add",
    "import": "Import",
    "msg1": "Message 1",
    "msg2": "Message 2",
    "year": "/",
    "month": "/",
    "date": "",
    "others": "others",
    "or": "or",
    "validate_num": "Please enter the number",
    "validate_ranger": "Numbers value from",
    "des_refresh_time" : "Time until 'not found' is displayed",
    "unit": "minute",
    "time": "Time",
    "today": "Today",
    "update": "Update"
  },
  "login": {
    "welcome": "Welcome to Spiral",
    "in_notice": "Please sign in to the system",
    "button": "Login",
    "email_message": "Please enter your e-mail address",
    "email_label": "Email",
    "email_placeholder": "Email",
    "validation_email": "is invalid email address.",
    "password_message": "Please enter your password",
    "password_label": "Password",
    "password_placeholder": "Password",
    "error": "Please check your email address and password",
    "invalid_email": "Please enter a valid email address",
    "forgot_pass": "Forgot your password",
    "support": "Support - ",
    "terms_of_use": "Terms of Use - ",
    "privacy": "Privacy Policy",
  },
  "user": {
    "title": "User list",
    "total": "user",
    "table": {
      "header_1": "ID",
      "header_2": "Company Name",
      "header_3": "Email",
      "header_4": "Password",
      "header_5": "Image Recognition",
      "header_6": "Action"
    },
    "message": {
      "add_user": "Added user",
      "validate": "Input correctly",
      "edit_info": "Edit user information",
      "confirm_delete_text": "Are you sure you want to completely delete all of this accounts components?"
    },
    "first_corp_key": "corpKey1",
    "second_corp_key": "corpKey2",
    "3rd_change_api": "Change",
    "no_result": "No user"
  },
  "schedule": {
    "title": "Flight Schedule",
    "total": "schedule",
    "edit": "Edit",
    "save": "Save",
    "label.date": "Date",
    "label.select": "Please Select",
    "label.title": "Schedule Name",
    "label.valid_flag": "Enable",
    "label.drone_id": "Target Drone",
    "label.plan": "Flight Plan",
    "label.marker": "Marker",
    "label.flight_date_ime": "Flight date and time",
    "label.repeat": "Repeat",

    "select.none": "None",
    "select.every_week": "Every Week",
    "select.monthly": "Monthly",
    "select.every_year": "Every Year",
    "select.every_day": "Every Day",

    "label.minute": "Minute",
    "label.hour": "Hour",
    "label.day": "Day",
    "label.month": "Month",
    "label.dow": "Day of Week",
    "label.action": "Action",
    "label.select_plan": "Select plan",
    "label.select_drone_id": "Select Drone No",
    "label.no_schedule": "Flight schedule is not registered",

    "label.name": "Schedule Name",
    "label.flight_schedule": "Flight date and time",
    "label.status": "Status",
    "label.drone": "Drone",
    "label.flight_plan": "Flight Plan",
    "label.number_of_flights": "Number Of Flights",
    "label.last_flight_date_and_time": "Last Flight Date And Time",
    "valid": "Enable",
    "invalid": "Disable",

    "pls_choose": "Please choose day of week",
    "pls_choose_house_minute": "Please enter hours and minutes",
  },
  "setting": {
    "menu": {
      "profile": "Profile",
      "icon": "Icon",
      "parameter": "Parameter",
      "droneType": "Drone Type",
      "privacySupports": "Privacy Supports",
    },
    "droneType": {
      "table": {
        "header_1": "No",
        "header_2": "Name",
        "header_3": "Flight Time",
        "header_4": "Flight Distance",
        "header_5": "Max Speed",
        "header_6": "Image",
        "header_7": "Action"
      },
      "file_select": "File Select",
      "edit": "Edit drone type",
      "required": "Drone Type name is required",
    },
    "icon": {
      "default_icon": "Default Drone Icon",
      "upload_img": "Upload Image"
    },

    "update_success": "Update successfully",
    "update_fail": "Update Fail",
    "do_you_want_delete_it": "Do you want to delete it?",
    "it_has_been_deleted": "It has been deleted",
    "could_not_be_deleted": "Could not be deleted",
    "error": "Error",
    "email_added": "Email added",
    "could_not_add_email": "Could not add email",
    "mail_existed": "email already existed",
    "corporate_intelligence": "Corp info",
    "email_management": "Email management",
    "please_add_an_email_address_to_send_an_alert_email": "Please add an email address to send an alert email",
    "can_not_get_ip_address": "Can not get IP address. Please check your network.",
    "charging_station": "Charging Station",
    "please_add_a_charging_station": "Please add a charging station",
    "charging_station_added": "Charging station added successfully.",
    "charging_station_existed": "Charging station already existed",
    "could_not_add_charging_station": "Could not add charging station",
    "station_id": "Station ID",
    "charging_station_deleted": "It has been deleted."
  },
  "plan": {
    "plan_name": "Flight Plan Name",
    "total": "plan",
    "next_tab-2": "2 Go to route confirmation settings",
    "next_tab-3": "3 Go to status/result MAP settings",
    "btn_pre_tab": "Pre",
    "btn_next_tab": "Next",
    "csv_upload_success": "Uploaded",
    "csv_delete_success": "It was deleted",
    "tab_2": {
      "upload": {
        "text_btn": "Upload",
        "title": "1. Drawing image upload",
        "desc_1": "Please upload the drawing image.",
        "desc_2": "Upload a top view for a more accurate review.",
        "label": "Drawing image",
        "selected": "Select File",
      },
      "set_scale": {
        "text_btn": "Scale",
        "title": "2. Drawing image scale",
        "desc_1": "Please set the scale of the uploaded drawing.",
        "desc_2": "Drag the drawing to enter the scale.",
        "label": "Scale"
      },
      "set_first_marker": {
        "text_btn": "Marker",
        "title": "3. Place a marker",
        "desc_1": "Click the first marker position to",
        "desc_2": "Set the starting point and direction of travel.",
        "btn_clear": "Reset",
      },
      "set_show_route": {
        "text_btn": "Route",
        "title": "4. Route confirmation",
        "desc_1": "Please check the flight route you have set.",
        "desc_2": "Change the flight route in",
        "btn_go_to_tab_update": "1 flight route settings.",
      },
    },
    "tab_3": {
      "upload": {
        "text_btn": "Upload",
        "title": "1. Drawing image upload",
        "desc_1": "For checking in conjunction with the video of the flight results",
        "desc_2": "Approximate preview map settings.",
        "desc_3": "Setting is not required if the drawing image is the same as the route confirmation setting.",
        "label": "Drawing image"
      },
      "setting_map": {
        "text_btn": "Marker",
        "title": "2. Place a marker",
        "desc_1": "Sets the position of the marker.",
        "desc_2": "Click or drag & place by drop.",
      },
    },
    "app": {
      "ver": "App Ver",
      "date": "Creation Date",
    },
    "drone": {
      "flight_route": "Flight Route",
      "title": "Flight plan",
      "plan_no": "Plan number",
      "plan_name": "Plan name",
      "created_time": "Creation time",
      "drone_type": "Drone Type",
      "frm_ver": "Firmware ver.",
      "speed": "Speed (m/s)",
      "place": "Flight Place",
      "fTemp": "fTemp",
      "emergency_marker": "Emergency Marker",
      "division": "Division",
      "number_of_markers": "Number of marker",
      "total_distance": "Total distance",
      "table_1": "Plan Name",
      "table_2": "Plan No.",
      "table_3": "status",
      "table_4": "Creation Date",
      "table_5": "Download",
      "table_6": "Delete",
      "tab_1": "Flight route setting",
      "tab_2": "Route confirmation setting",
      "tab_3": "Status/result MAP settings",
      "select_drone": "Select a drone",
      "added_flight_plan": "Added flight plan",
      "error_flight_plan": "The flight plan could not be updated.",
      "error_flight_plan_existed": "Flight plan number already existed",
      "select_file_plan": "Please specify a flight plan file.",
      "no_data_flight_plan": "No flight plan.",
      "message1": "Please enter the flight plan name.",
      "travel_number": "Travel plan number",
      "flight_number": "Flight number"
    },
    "robot": {
      "title": "Robot plan",
      "plan_no": "",
      "drone_type": "",
      "table_1": "ID",
      "table_2": "Movement plan name",
      "table_3": "Action",
      "table_4": "table_4",
      "table_5": "table_5",
      "table_6": "table_6",
      "select_robot": "Select robot landing",
      "added_move_plan": "Added a move plan",
      "error_move_plan": "Could not add move plan.",
      "select_file_plan": "Please specify the move plan file.",
      "add_robot_plan": "Make a robot plan",
      "message1": "Please enter the move plan name."
    },
    "marker_name": "Marker list",
    "marker": {
      "add_route": "Add Route",
      "drone": {
        "hover_t": "Hovering Time (s)",
        "height": "Height (m)",
        "angle": "Direction Angle (deg)",
        "wall_distance": "Wall distance (m)",
        "landing_port": "Charging Station",
        "direction": "Next Flight Direction",
        "marker_distance": "Distance to Next Marker (m)",
        "marker_distance_mechanism": "Marker distance mechanism",
        "marker_size": "Marker size (cm)",
        "mTemp": "mTemp",
        "mTemp2": "mTemp2",
      },
      "robot": {}
    },
    "error": {
      "empty_marker_distance_mechanism": "Marker distance mechanism is required",
      "empty_emergency_marker": "Empty emergency marker",
      "empty_place": "Empty place",
      "empty_division": "Empty division",
      "empty_version": "Empty version",
      "empty_date": "Empty created time",
      "empty_plan_no": "Empty plan no",
      "invalid_plan_no": "Plan No is integer from 101 to 299",
      "empty_plan_name": "Empty flight plan name",
      "invalid_drone_type": "Invalid drone type",
      "invalid_marker_id": "Marker id is number from 1 to 100",
      "invalid_speed": "Wrong flight speed",
      "invalid_marker_distance": "Invalid marker distance",
      "invalid_wall_distance": "Invalid wall distance",
      "invalid_marker_size": "Invalid size, must be from 1 to 100",
      "invalid_hover_t": "Invalid hover_t, must be from 0.1 to 999",
      "invalid_marker_height": "Invalid height",
      "invalid_direction": "Wrong flight direction",
      "invalid_first_marker": "First marker direction should be left or right",
      "invalid_csv": "Please upload a csv file after saving a flight plan"
    }
  },
  "home": {
    "title": "Dashboard",
    "preview_schedule": "Next flight schedule",
    "preview_result": "Latest flight results",
    "drone_title": "Drone",
  },
  "status": {
    "title-status": "Drone",
    "switch_view": "List display",
    "robot": {
      "status_text": "Robot",
      "message_1": "Add a robot to use this feature"

    },
    "drone": {
      "status_text": "Drone",
      "message_1": "Add a drone to use this feature"
    },
    "show_history": "Alert history",
    "alert": "Alert",
    "clear": "Clear",
    "take_off_now": {
      "success": "Send a takeoff command",
      "error": "Failed to send a takeoff command",
      "check_plan_takeoff": "Wrong charging station number",
      "pls_select_plan": "Please select a flight plan"
    },
    "small_marker": "small marker",
    "preview_schedule_title": "Flight schedule",
    "preview_result_title": "Flight result",
    "preview_result_alert": "Alert history",
    "preview_link": "View List",
    "preview_link_add": "Create new",
    "default_select": "Flight plan",
  },
  "result": {
    "total": "result",
    "alert": "Alert info",
    "display_message": "Display status detail",
    "small_marker": "small marker",
    "dowload": "Download",
    "compare": "Compare",
    "flight_result": 'Flight result',
    "text_zoom": "Load high resolution image",
    "label_select": "Flight Date",
    "no_data_result": "No search result.",

    "table.drone": "Drone",
    "table.flight_plan": "Flight Plan Name",
    "table.ID": "Plan No.",
    "table.number_of_flights": "Number of Flights",
    "table.last_flight_date_time": "Last Flight Data Time",
    "btn_sensor": "Sensor Measurement",
    "selected":"selected"
  },
  "avatar": {
    "notifi_success": "Updated",
    "notifi_error": "Cannot be set",
    "place_holder": "Flight plan name",
    "setting_avatar": "Corp info",
    "setting_mail": "Email management",
    "upload_image": "Upload image",
    "button_update": "Change profile picture",
    "main_title": "Setting",
    "notifi_error2": "Cannot update error",
    "detail_setting_mode": "Detail Setting Mode",
    "user_notification_url": "User Notification API URL"
  },

  "fgpassword": {
    "place_holder": "Email",
    "loading_text": "participate",
    "link_login": "Sign in"

  },

  "planMap": {
    "update_plan": "Updated move plan",
    "updated_flight_plan": "Updated flight plan",
    "could_not_add_move_plan": "Could not add move plan",
    "the_flight_plan": "The flight plan could not be updated",
    "map": "map",
    "travel_plan_name": "Travel plan name",
    "flight_plan_name": "Flight plan name",
    "Please_enter_the_move_plane_name": "Please enter the move plan name",
    "Please_enter_the_flight_plane_name": "Please enter the flight plan name",
    "update": "Update",
    "edit_marker": "Edit marker",

    "info": {
      "not_set_image": "Please upload map",
      "not_set_scale": "Please set scale",
      "set_first_marker_empty_plan": "Can not set first marker when plan is empty",
      "not_set_direction": "Please set direction of first marker"
    }
  },
  "planView": {
    "travel_plan_number": "Travel plan number",
    "robot_type": "Robot type",
    "moving_speed": "Moving speed",
    "moving_distance": "Moving distance",
    "marker_direction": "Marker direction",
    "direction_of_movement": "Direction of movement",
    "moving_angle": "Moving angle"
  },
  "menu-admin": {
    "manager": "Manager",
    "setting": "Setting",
    "logout": "Logout",
    "do_you_want_to_logout": "Do you want to log out?"
  },
  "menu": {
    "dashboard": "Dashboard",
    "drone_status": "Drone",
    "check_the_result": "Flight result",
    "flight_file": "Flight plan",
    "robot_status": "Robot status",
    "report": "Report",
    "schedule": "Flight schedule",
   
  },
  "mobileMenu": {
    "status": "Status",
    "scanning": "scanning",
    "textHome": "Home",
    "textSetting": "Setting",
    "textLogout": "Logout",
  },
  "scan": {
    "flight_plan_id": "Flight plan ID",
    "enter_flight_plan_id": "Enter Flight Plan ID",
    "is_not_set": "Is not set",
    "please_enter_the_move_plan_number": "Please enter the move plan number",
    "please_enter_your_flight_plan_number": "Please enter your flight plan number",
    "the_marker_does_not_load": "The marker does not load. Please scan again.",
    "scaning_multiple_marker": "Marker scan error.",
    "at_the_time_of_creation": "At the time of creation",
    "speed": "⾶⾏speed",
    "water_travel_distance": "Water travel distance",
    "marker_ID": "Marker ID",
    "distance_to_the_next_marker": "Distance to the next marker",
    "capture_the_image": "Capture the image",
    "please_enter": "Please enter",
    "outward": "Outward",
    "return": "Return",
    "btn-plan": "Read the marker",
    "title-scan": "Scan",
    "validate_input_required": "Please enter your plan number",
    "validate_input_format": "Wrong flight plan no.",
  },
  "dialog_delete": {
    "message_text": "Do you want to delete?",
    "option_yes": "OK",
    "option_no": "Cancel",
    "confirm_success": "It has been deleted.",
    "confirm_error": "Can not delete it.",
    "confirm_success2": "Deleted user",
    "confirm_error2": "Failed to delete user",
    "deleted_drone": "Drone has been deleted",
    "deleted_robot": "Robot has been deleted",
    "error_delete_robot": "Unable to delete robot",
    "error_delete_drone": "Drone cannot be deleted"
  },
  "map": {
    "upload_image": "Please upload the map image",
    "menu_text": "Report",
    "menu_action": "Check the result",
    "mode_map": "Map mode",
    "red_text": "No results",
    "robot_list": "Robot list",
    "drone_list": "Drone list",
    "flight_plan_number": "Flight plan number",
    "flight_number": "Flight number",
    "flight_name": "Flight name",
    "flight_time": "Flight time"
  },
  "video": {
    "video_text": "Video ",
    "button_zoom": "Zoom",
    "battery_info": "Battery",
  },

  "mobile_app": {
    "drone": {
      "select_drone": "Select a drone"
    },
    "robot": {
      "select_robot": "Select a robot"
    }
  },

  "download": {
    "download_failed": "Download failed"

  },
  "mode": {
    "normal_mode": "Normal",
    "map_mode": "Map",
    "dual_mode": "Compare"
  },
  "drone_selected": {
    "not_selected": "Please select Drone and Result",
  },
  "drone_info": {
    "drone_id": "Drone Id",
    "flight_plan_no": "Flight Plan No",
    "flight_name": "Flight Name",
    "battery": "Battery"
  }, 
  "reset_password": {
    "message_send_email": "An email has been sent. Please access the URL described in the email and reset a password.",
    "message_error_password": "Password does not match.",
    "message_success_password": "Change password successfully.",
    "header_modal_send_mail": "Forgot password",
    "email_address": "Email address",
    "send_request": "Send request",
    "btn_reset_password": "Reset password",
    "back_login": "Back login",
    "confirm_password": "Confirm password",
    "password": "Password",
    "validate_send_email": "Please enter full information.",
    "error_invalid_email": "Please enter a valid email address.",
    "validate_send_password": "Please enter full information.",
    "error_invalid_password": "Password must be at least 3 characters.",
    "error_response_data": "User does not exist!"
  }, 
  "privacy_supports" : {
    "confirm_alert": "Do you want to save your changes?",
    "support": "Support",
    "terms_of_service": "Terms of service",
    "privacy_policy": "Privacy policy",
    "last_update": "Last Update"
  },
  "Search_Pagination": {
    "search": "Search",
    "pageNum": "Number of items a page",
    "checkDate": 'Date to must be greater than date from.',
    "device_no": "Drone",
    "flight_plan": "Flight Plan Name",
    "plan_no": "Plan No.",
    "creation_date": "Creation Date",
    "last_flight_date": "Last Flight Date Time",
    "flight_date": "Flight Date And Time",
    "plan_name": "Plan Name",
    "schedule_name": "Schedule Name",
    "format-date": "Y/M/D",
  },
  "kiriha": {
    "label": "Observing Kiriha",
  }
};
export default lang_en;