import React, { useState, useEffect, memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import SensorInfo from '../sensor/SensorInfo';
import { timeSmallConverter } from '../../utils/utils';
import * as Images from '../images';
import { isMobileOnly } from 'react-device-detect';

const ChartContext = React.createContext();

const DeviceSonsorPreview = (props) => {
  const { t } = useTranslation();
  const { 
    dataSensor, 
    dataSensorList, 
    takeoffUtc, 
    planNo, 
    isFlying, 
    isMainView, 
    sizeBrowser, 
    sizeMain, 
    isSettingLegendMB, 
    setIsSettingLegendMB,
    elementMainView,
    chartSetting,
    updateChartSetting,
    colorSensor
  } = props;

  const [isViewChart, setIsViewChart] = useState(true);
  const [isMultiDate, setIsMultiDate] = useState(false);
  const [sensorCsv, setSensorCsv] = useState(null);
  const [sensorsInfo, setSensorsInfo] = useState(null);
  const [sensorsChart, setSensorsChart] = useState(null);
  const [sensorValueMin, setSensorValueMin] = useState(false);
  const [sensorValueMax, setSensorValueMax] = useState(false);

  const [isSettingTime, setIsSettingTime] = useState(true);

  const [isViewFromSetting, setIsViewFromSetting] = useState(false);
  const btnSettingRef = useRef(null);
  const formSettingRef = useRef(null);

  useEffect(() => {
    if (dataSensorList) {
      handleCommon(dataSensorList);
      updateChart(dataSensorList);
    }
  }, [dataSensorList])

  useEffect(() => {
    if(formSettingRef.current && !isMobileOnly) {
      setPositionFormSetting();
    }
  }, [sizeBrowser, sizeMain, isViewFromSetting])

  useEffect(() => {
    if (elementMainView !== "sensor") {
      setIsViewFromSetting(false)
    }
  }, [elementMainView])

  const setPositionFormSetting = () => {
    if(btnSettingRef.current) {
      const { width, top, left } = btnSettingRef.current.getBoundingClientRect();
      const wEle = formSettingRef.current.offsetWidth;
      const hEle = formSettingRef.current.offsetHeight;

      formSettingRef.current.style.top = top - hEle + width + 2 + "px";
      formSettingRef.current.style.left = left - wEle - 2 + "px";
    }
  }

  const handleCommon = (dataSensorList) => {
    let min = Math.min(...dataSensorList.sensor_meas_arr.map(item => Math.min(...item.value_arr.filter(it => !isNaN(it)))));
    let max = Math.max(...dataSensorList.sensor_meas_arr.map(item => Math.max(...item.value_arr.filter(it => !isNaN(it)))));
    const counterDays = dataSensorList.meas_t_arr.reduce((prev, item) => {
      const date = new Date(item * 1000).getDate();
      if (!prev.includes(date)) {
        return prev.concat(date);
      }
      return prev;
    }, []);

    setSensorsChart(dataSensorList);
    setSensorCsv(convertSensorCsv(dataSensorList));
    setSensorsInfo(convertSensorInfo(dataSensorList));
    setSensorValueMin(min);
    setSensorValueMax(max);
    setIsMultiDate(counterDays.length > 1 ? true : false);
  }

  const convertSensorCsv = (data) => {
    return data.meas_t_arr.map((item, index) => {
      const sensorMeas = data.sensor_meas_arr.map((sensor) => {
        return {
          [sensor.sensor + "(" + sensor.unit + ")"]: sensor.value_arr[index] !== "NaN" ? sensor.value_arr[index] : "",
        };
      });

      return {
        Marker: data.marker_arr[index],
        Time: timeConverter(item, t),
        ...Object.assign({}, ...sensorMeas),
      };
    });
  }

  const convertSensorInfo = (data) => {
    const marker = "marker";
    const time = "time";

    let mapContentSensors = [],
      unitSensor = {},
      uniqSensor = [marker, time];

    data.sensor_meas_arr.map((item) => {
      Object.assign(unitSensor, { [item.sensor]: item.unit });
      uniqSensor.push(item.sensor);
    });

    data.meas_t_arr.map((item, index) => {
      const sensor = {};
      sensor[marker] = data.marker_arr[index];
      sensor[time] = timeConverter(item, t);

      const sensorMeas = data.sensor_meas_arr.map((sensor) => {
        return {
          [sensor.sensor]:
            sensor.value_arr[index] !== "NaN" ? sensor.value_arr[index] : "",
        };
      });

      mapContentSensors.push({
        ...sensor,
        ...Object.assign({}, ...sensorMeas),
      });
    });

    return { mapContentSensors, uniqSensor, unitSensor };
  }

  const timeConverter = (UNIX_timestamp) => {
    if (!UNIX_timestamp) {
      return "";
    }
    const a = new Date(UNIX_timestamp * 1000);
    const year = a.getFullYear();
    const month = ('0' + (a.getMonth() + 1)).substr(-2);
    const date = ('0' + a.getDate()).substr(-2);
    const hour = ('0' + a.getHours()).substr(-2);
    const min = ('0' + a.getMinutes()).substr(-2);
    const sec = ('0' + a.getSeconds()).substr(-2);
  
    return year + "." + month + "." + date + ' ' + hour + ':' + min + ':' + sec;
  }

  const timeConverterChart = (UNIX_timestamp) => {
    if (!UNIX_timestamp) {
      return "";
    }
    const a = new Date(UNIX_timestamp * 1000);
    const year = a.getFullYear();
    const month = ('0' + (a.getMonth() + 1)).substr(-2);
    const date = ('0' + a.getDate()).substr(-2);
    const hour = ('0' + a.getHours()).substr(-2);
    const min = ('0' + a.getMinutes()).substr(-2);
    const sec = ('0' + a.getSeconds()).substr(-2);
  
    return year + '-' + month + '-' + date + 'T' + hour + ':' + min + ':' + sec;
  }

  const updateChart = (sensorsChart) => {
    if(sensorsChart) {
      const newChartSetting = {...chartSetting};

      const labels = sensorsChart.meas_t_arr.map(item => +item - takeoffUtc);
      const datasets = sensorsChart.sensor_meas_arr.map((item, index) => {
        const rgb = colorSensor[index];
        let dataNormalize;
        const maxAbsItem = Math.max(...item.value_arr.map(Math.abs).filter(value => !isNaN(value)));
        
        if(newChartSetting.isNormalize) {
          dataNormalize = item.value_arr.map((it, _, arr) => {
            if(it !== "NaN") {
              return +it / maxAbsItem;
            }
            return it
          } );
        }
    
        return {
          label: item.sensor + " [" + item.unit + "]",
          data: newChartSetting.isNormalize ? dataNormalize : item.value_arr,
          borderColor: `rgb(${rgb})`,
          backgroundColor: `rgb(${rgb}, 0.5)`,
        }
      })

      const min = Math.min(...sensorsChart.sensor_meas_arr.map(item => Math.min(...item.value_arr.filter(it => !isNaN(it)))));
      const max = Math.max(...sensorsChart.sensor_meas_arr.map(item => Math.max(...item.value_arr.filter(it => !isNaN(it)))));

      newChartSetting.dataChart = { labels, datasets };
      newChartSetting.maxX = labels[labels.length - 1];
      newChartSetting.minX = 0;
      newChartSetting.maxY = max;
      newChartSetting.minY = min < 0 ? min : 0;

      updateChartSetting(newChartSetting);
    }
  }

  const changeLegendStatus = (index) => {
    const statusLegendList = [...chartSetting.statusLegendList].map((item, i) => i === index ? !item : item);
    
    updateChartSetting({...chartSetting, statusLegendList});
  }

  const changeNormalizeStatus = (status) => {
    const datasets = sensorsChart.sensor_meas_arr.map((item, index) => {
      const rgb = colorSensor[index];
      let dataNormalize;
      const maxAbsItem = Math.max(...item.value_arr.map(Math.abs).filter(value => !isNaN(value)));
      
      if(status) {
        dataNormalize = item.value_arr.map((it, _, arr) => {
          if(it !== "NaN") {
            return +it / maxAbsItem;
          }
          return it
        } );
      }
  
      return {
        label: item.sensor + " [" + item.unit + "]",
        data: status ? dataNormalize : item.value_arr,
        borderColor: `rgb(${rgb})`,
        backgroundColor: `rgb(${rgb}, 0.5)`,
      }
    })
    const optionsChart = {...chartSetting.optionsChart};
    optionsChart.scales.y.min = getMinYAxis(status);
    optionsChart.scales.y.max = getMaxYAxis(status);

    optionsChart.scales.y.ticks.callback = (val) => {
      if(!(+val === 1.2 || +val === -1.2)) return val;
    }

    optionsChart.scales.y.ticks.stepSize = status ? 0.2 : getStepSizeYAxis(status);
    optionsChart.scales.y.ticks.precision = status ? 2 : 0;

    const newChartSetting = {...chartSetting};
    newChartSetting.dataChart.datasets = datasets;
    newChartSetting.isNormalize = status;
    newChartSetting.optionsChart = optionsChart;
    newChartSetting.minY = status ? sensorValueMin < 0 ? -1 : 0 : sensorValueMin;
    newChartSetting.maxY = status ? 1 : sensorValueMax;
    newChartSetting.stepSizeY = status ? 0.2 : undefined;
    
    updateChartSetting(newChartSetting);
  }

  // Axis Y setting
  const getMaxYAxis = (isNormalize, maxY) => {
    if(isNormalize) {
      if(maxY && +maxY <= 1 && +maxY >= -1) return +maxY;
      return 1.2;
    }

    if(maxY) return +maxY;
  }
  const getMinYAxis = (isNormalize, minY) => {
    if(isNormalize) {
      if(minY && +minY >= -1 && +minY <= 1) return +minY;
      if(sensorValueMin < 0) return -1.2;
      return 0;
    }

    if(minY) return +minY;

    return sensorValueMin;
  }
  const getStepSizeYAxis = (isNormalize) => {
    const { stepSizeY } = chartSetting;
    if(isNormalize && stepSizeY) return +stepSizeY;

    return stepSizeY;
  }
  const settingStepSizeY = (val) => {
    const newChartSetting = {...chartSetting};
    const stepSizeY = getMaxYAxis(newChartSetting.isNormalize, val);
    
    newChartSetting.stepSizeY = val;
    newChartSetting.optionsChart.scales.y.ticks.stepSize = stepSizeY;
    newChartSetting.optionsChart.scales.y.ticks.precision = stepSizeY % 1 !== 0 ? 2 : 0;
    
    updateChartSetting(newChartSetting);
  }
  const settingMinY = (val) => {
    const newChartSetting = {...chartSetting};
    const minY = getMinYAxis(newChartSetting.isNormalize, val);

    newChartSetting.minY = val;
    newChartSetting.optionsChart.scales.y.min = minY;
    
    updateChartSetting(newChartSetting);
  }
  const settingMaxY = (val) => {
    const newChartSetting = {...chartSetting};
    const maxY = getMaxYAxis(newChartSetting.isNormalize, val);

    newChartSetting.maxY = val;
    newChartSetting.optionsChart.scales.y.max = maxY;
    
    updateChartSetting(newChartSetting);
  }
  // Axis X setting
  const settingMinX = (e) => {
    const { value, type } = e.target;
    const newChartSetting = {...chartSetting};

    newChartSetting.minX = value;
    switch (type) {
      case "number": newChartSetting.optionsChart.scales.x.min = +value; break;
      case "time": newChartSetting.optionsChart.scales.x.min = calculateTimeDifference(value); break;
      case "datetime-local": newChartSetting.optionsChart.scales.x.min = calculateMultiTimeDifference(value); break;
      default: break;
    }
    
    updateChartSetting(newChartSetting);
  }
  const settingMaxX = (e) => {
    const { value, type } = e.target;
    const newChartSetting = {...chartSetting};

    newChartSetting.maxX = value;
    switch (type) {
      case "number": newChartSetting.optionsChart.scales.x.max = +value; break;
      case "time": newChartSetting.optionsChart.scales.x.max = calculateTimeDifference(value); break;
      case "datetime-local": newChartSetting.optionsChart.scales.x.max = calculateMultiTimeDifference(value); break;
      default: break;
    }
    
    updateChartSetting(newChartSetting);
  }
  const settingStepSizeX = (val) => {
    const newChartSetting = {...chartSetting};
    
    newChartSetting.stepSizeX = val;
    newChartSetting.optionsChart.scales.x.ticks.stepSize = val ? +val < 0 ? 1 : (+val).toFixed(1) : undefined;
    newChartSetting.optionsChart.scales.x.ticks.precision = val % 1 === 0 ? 0 : 1;
    
    updateChartSetting(newChartSetting);
  }

  // changeOptionViewXAxis
  const changeOptionViewXAxis = (status) => {
    const newChartSetting = {...chartSetting};
    newChartSetting.isConvertHour = status;
    newChartSetting.optionsChart.scales.x.ticks.callback = val => status ? timeSmallConverter(+takeoffUtc + +val) : val;
    newChartSetting.optionsChart.plugins.tooltip.callbacks = { 
      title: status ? 
        tooltipItems => timeSmallConverter(+tooltipItems[0].label + +takeoffUtc) : 
        tooltipItems => tooltipItems[0].label
    };

    if(isMultiDate) {
      newChartSetting.maxX = status ? timeConverterChart(Number(takeoffUtc) + Number(newChartSetting.maxX)) : calculateMultiTimeDifference(newChartSetting.maxX);
      newChartSetting.minX = status ? timeConverterChart(Number(takeoffUtc) + Number(newChartSetting.minX)) : calculateMultiTimeDifference(newChartSetting.minX);
    } else {
      newChartSetting.maxX = status ? timeSmallConverter(Number(takeoffUtc) + Number(newChartSetting.maxX)) : calculateTimeDifference(newChartSetting.maxX);
      newChartSetting.minX = status ? timeSmallConverter(Number(takeoffUtc) + Number(newChartSetting.minX)) : calculateTimeDifference(newChartSetting.minX);
    }

    
    updateChartSetting(newChartSetting);
  }
  const calculateTimeDifference = (currentTime) => {
    const parseTime = (time) => {
      const [hours, minutes, seconds] = time.split(":").map(Number);
      return hours * 3600 + minutes * 60 + seconds;
    };
  
    const secondsTakeoff = parseTime(timeSmallConverter(takeoffUtc));
    const seconds = parseTime(currentTime);
  
    const timeDifference = seconds - secondsTakeoff;
  
    return timeDifference;
  }
  const calculateMultiTimeDifference = (currentTime) => {
    const seconds = Date.parse(currentTime) / 1000;
  
    const timeDifference = seconds - takeoffUtc;
  
    return timeDifference;
  }

  const getType = () => {
    if(chartSetting.isConvertHour) {
      if(isMultiDate) return "datetime-local"
      return "time"
    }

    return "number"
  }

  const renderChartContent = (isPreview) => (
    <ChartContext.Provider
      value={{
        chartSetting: chartSetting,
        isMultiDate: isMultiDate,
        changeLegendStatus: (index) => changeLegendStatus(index),
        changeNormalizeStatus: (status) => changeNormalizeStatus(status),
        settingStepSizeY: (val) => settingStepSizeY(val),
        settingMinY: (val) => settingMinY(val),
        settingMaxY: (val) => settingMaxY(val),
        settingStepSizeX: (val) => settingStepSizeX(val),
        settingMinX: (val) => settingMinX(val),
        settingMaxX: (val) => settingMaxX(val),
        changeOptionViewXAxis: (status) => changeOptionViewXAxis(status),
        isSettingLegendMB: isSettingLegendMB,
        updateIsSettingLegendMB: (status) => setIsSettingLegendMB(status)
      }}
    >
      <SensorInfo
        context={ChartContext}
        isViewChart={isViewChart}
        sensorCsv={sensorCsv}
        sensorsChart={sensorsChart}
        sensorsInfo={sensorsInfo}
        takeoffUtc={takeoffUtc}
        planNo={planNo}
        setIsViewChart={(status) => setIsViewChart(status)}
        isPreview={isPreview}
        typeSetting={getType()}
        isConvertHour={chartSetting.isConvertHour}
        minX={chartSetting.minX}
        maxX={chartSetting.maxX}
        stepSizeX={chartSetting.stepSizeX}
        settingMinX={(e) => settingMinX(e)}
        settingMaxX={(e) => settingMaxX(e)}
        settingStepSizeX={(e) => settingStepSizeX(e)}
        minY={chartSetting.minY}
        maxY={chartSetting.maxY}
        stepSizeY={chartSetting.stepSizeY}
        settingMinY={(e) => settingMinY(e)}
        settingMaxY={(e) => settingMaxY(e)}
        settingStepSizeY={(e) => settingStepSizeY(e)}
        changeOptionViewXAxis={(status) => changeOptionViewXAxis(status)}
      />
    </ChartContext.Provider>
  )

  const renderPreviewSensor = () => {
    const { dataChart } = chartSetting;
    return (
      <div 
        className='preview-sensor'
      >
        {isFlying ?
          isViewChart ? 
            dataChart && renderChartContent(true)
            : 
            <div className="preview-sensor-sub-table ct-scroll">
              {dataChart &&
                <div className="preview-sensor-sub-table-wrapper">
                  <div className="sub-table-header">
                    <div className="sub-table-row">
                      <div className="unit"></div>
                      {dataChart.labels.map(item => (
                        <div className="time">{timeSmallConverter(+takeoffUtc + item)}</div>
                      ))}
                    </div>
                  </div>
                  <div className="sub-table-body">
                    {dataChart.datasets.map(item => (
                      <div className="sub-table-row">
                        <div className="unit">{item.label}</div>
                        {item.data.map(val => (
                          <div className="val">{isNaN(val) ? undefined : val}</div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              }
            </div>
          :
          dataSensor && (
            <div className="preview-sensor-sub-table ct-scroll" style={{ margin: 0 }}>
              <div className="preview-sensor-sub-table-wrapper">
                <div className="sub-table-header">
                  <div className="sub-table-row">
                    <div className="unit"></div>
                    <div className="time">{timeSmallConverter(dataSensor.utc)}</div>
                  </div>
                </div>
                <div className="sub-table-body">
                  {dataSensor.sensors.map(item => (
                    <div className="sub-table-row">
                      <div className="unit">{item.sensor}</div>
                      <div className="val">{isNaN(item.value) ? undefined : item.value}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )
        }
        {isFlying &&
          <div className='preview-sensor-actions'>
            {isViewChart &&
              <>
                {isMobileOnly &&
                  <button 
                    className='btn-dataset' 
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsSettingLegendMB(true)
                    }}
                  >
                    Dataset
                  </button>
                }

                <button 
                  className={'btn-setting' + (isViewFromSetting ? " active" : "")}
                  ref={btnSettingRef}
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsViewFromSetting(!isViewFromSetting)
                  }}
                >
                  <Images.IconSetting color="#5F55C4" />
                </button>

                {isViewFromSetting &&
                  <div className='form-setting' ref={formSettingRef} onClick={e => e.stopPropagation()}>
                    <div className='form-setting-header'>
                      <div className="switch-setting">
                        <button className={isSettingTime ? "active" : ""} onClick={() => setIsSettingTime(true)}>Time</button>
                        <button className={!isSettingTime ? "active" : ""} onClick={() => setIsSettingTime(false)}>Value</button>
                      </div>
                      <button onClick={() => setIsViewFromSetting(false)}>
                        <Images.IconClose />
                      </button>
                    </div>

                    <div className='form-setting-body'>
                      {isSettingTime ?
                        <>
                          <div className='form-item'>
                            <label>Min</label>
                            <input type={getType()} step={chartSetting.isConvertHour ? 2 : 1} value={chartSetting.minX} onChange={(e) => settingMinX(e)} />
                          </div>
                          <div className='form-item'>
                            <label>Max</label>
                            <input type={getType()} step={chartSetting.isConvertHour ? 2 : 1} value={chartSetting.maxX} onChange={(e) => settingMaxX(e)} />
                          </div>
                          <div className='form-item'>
                            <label>Step size</label>
                            <input type="number" value={chartSetting.stepSizeX} onChange={(e) => settingStepSizeX(e.target.value)} />
                          </div>
                        </>
                        :
                        <>
                          <div className='form-item'>
                            <label>Min</label>
                            <input type="number" value={chartSetting.minY} onChange={(e) => settingMinY(e.target.value)} />
                          </div>
                          <div className='form-item'>
                            <label>Max</label>
                            <input type="number" value={chartSetting.maxY} onChange={(e) => settingMaxY(e.target.value)} />
                          </div>
                          <div className='form-item'>
                            <label>Step size</label>
                            <input type="number" value={chartSetting.stepSizeY} onChange={(e) => settingStepSizeY(e.target.value)} />
                          </div>
                        </>
                      }
                    </div>

                    <div className='form-setting-footer' style={!isSettingTime ? { opacity: 0, visibility: "hidden" } : undefined}>
                      <label className="form-radio">
                        <input type="radio" name="x-option" checked={!chartSetting.isConvertHour} defaultChecked onChange={() => changeOptionViewXAxis(false)} />
                        <span className='checkmark'></span>
                        Elapsed Time
                      </label>
                      <label className="form-radio">
                        <input type="radio" name="x-option" checked={chartSetting.isConvertHour} onChange={() => changeOptionViewXAxis(true)} />
                        <span className='checkmark'></span>
                        Hour and Minute
                      </label>
                    </div>
                  </div>
                }
              </>
            }

            <div className='switch-btn-chart'>
              <button 
                className={isViewChart ? 'active' : ""}
                onClick={(e) => {
                  e.stopPropagation()
                  setIsViewChart(true)
                }}
              >
                <Images.IconChartSensors color="currentColor" />
              </button>
              <button 
                className={!isViewChart ? 'active' : ""}
                onClick={(e) => {
                  e.stopPropagation()
                  setIsViewChart(false)
                }}
              >
                <Images.IconTableSensors color="currentColor" />
              </button>
            </div>
          </div>
        }
      </div>
    )
  }

  if (isMainView && isFlying) {
    if (sensorsChart && sensorsInfo && sensorCsv) return (
      renderChartContent()
    );
  } else {
    return renderPreviewSensor();
  }
};

export default memo(DeviceSonsorPreview);